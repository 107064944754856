<template>
  <div class="distribution-again">
    <div class="distribution-body">
      <div class="distribution-title">
        <!-- 重新分配批准请求 -->
        {{ $t("label.redistributionrequest") }}
      </div>
      <div class="distribution-body">
        <div class="title">
          <!-- 重新分配批准请求 -->
          {{ $t("label.redistributionrequest") }}
        </div>
        <div class="body">
          <el-form
            :model="formData"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <!-- 批准人 -->
            <el-form-item :label="$t('label.approver')" prop="people">
              <!-- <el-input
                v-model="formData.people"
                readonly="readonly"
              ></el-input>
              <span class="search-button" @click="searchPeo"
                >(
                查找
                {{ $t("label.ems.lookup2") }}
                )</span
              >
              <span class="is-have">
                已分配批准人
                {{ $t("label.assignedapprover") }}
                {{ owner }}</span
              > -->
              <el-select
                v-model="formData.people"
                :placeholder="$t('label.searchs')"
                clearable
                multiple
                remote
                class="no-suffix"
                style="width: 100% !important"
              >
                <!-- @change="clearFilterOptions"> -->
                <el-option class="searchTipBox" v-show="hideStatus">
                  <span
                    @click="remoteSearch()"
                    style="display: inline-block; width: 100%"
                  >
                    <i class="el-icon-search"></i>
                    <span style="padding-left: 10px">
                      <!-- 前往精准搜索 -->
                      {{
                        $t("vue_label_commonobjects_detail_to_precise_search")
                      }}
                    </span>
                  </span>
                </el-option>
                <el-option
                  v-for="item in selectopction"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- 备注 -->
            <el-form-item :label="$t('label.description')">
              <el-input type="textarea" v-model="formData.remarks"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                @click="submitForm('ruleForm')"
                class="leftdis"
                :loading="approvalLoading"
              >
                <!-- 重新分配批准请求 -->
                {{ $t("label.redistributionrequest") }}</el-button
              >
              <el-button @click="cancel('ruleForm')" :loading="approvalLoading">
                <!-- 取消 -->
                {{ $t("label.cancel") }}
              </el-button>
              <!-- <div
                @click="submitForm('ruleForm')"
                class="submit-button"
              >
                重新分配批准请求
              </div> -->
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="shuttle-box">
      <!-- 选择批准人 -->
      <el-dialog
        :title="$t('label.selectapprover')"
        :visible.sync="isSearch"
        width="500px"
      >
        <shuttle-box
          @changeSearch="changeSearch"
          @deliverName="deliverName"
        ></shuttle-box>
      </el-dialog>
    </div>
    <!-- 查找/查找多选 -->
    <el-dialog
      :title="$t('label.searchs')"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      :before-close="beforeClose"
      append-to-body
    >
      <div
        :style="{
          height: dialogBodyHeight,
          overflow: 'auto',
          padding: '0 20px',
        }"
      >
        <search-table
          ref="searchTable"
          :checked="true"
          :relevant-objid="relevantObjId"
          :relevant-prefix="relevantPrefix"
          @changeSelect="changeSelect"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { commonApproval } from "./api.js";
import shuttleBox from "@/views/approval/components/shuttle-box.vue";
import detailSendCard from "@/utils/robotMessage.js";
import SearchTable from "@/components/Form/search-table";
export default {
  name: "distribution-again",
  data() {
    return {
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      approvalLoading: false,
      isSearch: false,
      showSearchTable: false,
      hideStatus: true,
      relevantObjId: "user",
      relevantPrefix: "005",
      selectopction: [],
      //审批人ID
      fprId: "",
      formData: {
        people: [],
        remarks: "",
      },
      rules: {
        // 值不能为空
        people: [
          {
            required: true,
            message: this.$i18n.t("label.value.notnull"),
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    owner() {
      return localStorage.getItem("username");
    },
  },
  components: {
    shuttleBox,
    SearchTable,
  },
  mounted() {
    if (this.route.path == "/approvalCenter/distributionAagain") {
      document.title = this.$setTitle(this.$i18n.t("label.accraditation"));
    }
  },
  methods: {
    setFieldReltaion() {},
    //父组件选中批准人
    changeSelect(res) {
      if (Array.isArray(res)) {
        res.forEach((item) => {
          this.formData.people.push(item.id);
          this.selectopction.push(item);
        });
      } else {
        this.formData.people.push(res.data.id);
        this.selectopction.push(res.data);
      }
      this.showSearchTable = false;
    },
    beforeClose() {
      this.showSearchTable = false;
    },
    //查找多选
    remoteSearch() {
      let dom = document.getElementsByClassName("el-select-dropdown");
      dom[0].style.display = "none";
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.init();
      });
    },
    //获取审批人名称和ID
    deliverName(name, id) {
      this.formData.people = name;
      this.fprId = id;
    },
    //重新分配
    async distributionAgain() {
      this.formData.people.forEach((item, index) => {
        if (typeof item != "string") {
          this.formData.people.splice(index, 1);
        }
      });
      let people = this.formData.people.join(",");
      let option = {
        comments: this.formData.remarks,
        workItemId: this.$route.query.value,
        actionType: "Reassign",
        fprId: people,
      };
      try {
        let result = await commonApproval(option);
        if (result.returnCode == "SUCCESS") {
          // this.$router.push('/approvalCenter')
          this.$router.go(-1);
          this.approvalLoading = false;
          if (
            this.$route.query.detailGroupId &&
            this.$route.query.detailGroupId != ""
          ) {
            detailSendCard(this.$route.query.detailGroupId, {
              approval_value: this.$i18n.t(
                "vue_label_approve_reassignapproval",
                {
                  name: localStorage.getItem("username"),
                  people: this.selectopction.map((res) => res.name).join(),
                }
              ),
              title: this.$i18n.t("vue_label_chat_approval_message"),
              type: "APPROVAL_CARD",
              recordId: this.$route.query.recordId,
              changer: localStorage.getItem("username"),
              // body: [
              //   {
              //     fieldLable: "日期",
              //     fieldValue: transformTime(new Date().getTime())
              //   }
              // ]
            });
          }
          this.$Bus.$emit("cancel-not-loading", true);
        } else {
          this.approvalLoading = false;
          this.$message({
            showClose: true,
            type: "warning",
            message: result.stackTrace || result.returnInfo,
          });
          this.$router.push("/approvalCenter");
        }
      } catch {
        this.approvalLoading = false;
        this.$router.push("/approvalCenter");
      }
    },
    //收起弹出框
    changeSearch() {
      this.isSearch = false;
    },
    //查找批准人
    searchPeo() {
      this.isSearch = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.approvalLoading = true;
          this.distributionAgain();
        } else {
          return false;
        }
      });
    },
    cancel(formName) {
      this.$refs[formName].resetFields();
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.searchTipBox {
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  padding: 0 20px;
  margin-bottom: 0;

  &:hover {
    background: #f5f7fa;
  }
}
.distribution-again {
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: auto;
  .distribution-body {
    width: 100%;
    .distribution-title {
      height: 49px;
      font-size: 18px;
      background: #f8f8f8;
      font-weight: 500;
      color: #222222;
      line-height: 49px;
      padding-left: 20px;
    }
    .distribution-body {
      .title {
        margin-top: 14px;
        margin-left: 20px;
        font-size: 14px;
        color: #222;
      }
      .body {
        width: 50% !important;
        margin: 20px auto;
        .el-form {
          .search-button {
            cursor: pointer;
            color: #006dcc;
            font-size: 12px;
            margin-left: 20px;
          }
          .is-have {
            font-size: 12px;
            margin-left: 100px;
          }
          .el-input {
            width: 100% !important;
          }
          .el-form-item__label {
            font-size: 12px;
          }
          .el-input__inner {
            width: 100% !important;
            height: 30px;
            line-height: 30px;
          }
          .el-textarea {
          }
          .el-textarea__inner {
            height: 163px;
            resize: none;
          }
          .submit-button {
            cursor: pointer;
            width: 127px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            color: #006dcc;
            font-size: 12px;
            border-radius: 3px;
            border: 1px solid #dddbda;
            float: right;
            margin-right: 15px;
            font-weight: 400;
          }
          .cancels-button {
            margin-right: 240px;
            cursor: pointer;
            width: 50px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            color: #006dcc;
            font-size: 12px;
            border-radius: 3px;
            border: 1px solid #dddbda;
            float: right;
            font-weight: 400;
          }
        }
      }
    }
  }
  .leftdis {
    margin-left: 230px;
  }
  .el-button {
    padding: 6px 15px;
    color: #006dcc;
    font-size: 12px;
  }
}
</style>
